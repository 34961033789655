import React from "react";
import { Tooltip } from "antd";
import MediaQuery from "react-responsive";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import ArrowBlack from "../../../../../../assets/images/dashboard/arrow-black.svg";
import Plus from "../../../../../../assets/images/dashboard/plus.svg";
import Minus from "../../../../../../assets/images/dashboard/minus.svg";
import Equal from "../../../../../../assets/images/dashboard/equal.svg";
import Rate from "../../../../../../assets/images/dashboard/rate.svg";
import Prize from "../../../../../../assets/images/dashboard/prize.svg";
import CardDetailsByGraph from "../component/CardDetailsByGraph"
import SeeMoreDetails from "../component/SeeMoreDetails"
import Carousel from "react-multi-carousel";
import {
    getCardImageURL,
    getFeaturesIcon,
    commaSeparator,
    valueWithCommas,
    getToolTipConstant,
    toFixedToDecimal,
    firstYear,
    secondYear,
    lastYear
} from "../../../../../../utils/constants"
import "../../../../../../common/styles/dashboard.scss"
import './CardDetails.scss';


function CardDetailsCarousel(args)  {
    
    const { 
        getCardsList,
        onGetDate,
        avgSpending,
        spending,
        selectedDate,
        topYearProjection, 
        topIncludedIntroBonus,
        selectedCategories,
        selectedFeatures,
    } = args;
    
    // 
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const [isFadeIn, setIsFadeIn] = React.useState(false);
    const [carouselHeight, setCarouselHeight] = React.useState(null);
    const carouselRef = React.useRef(null);
    const carouselIndexRef = React.useRef(null);
    
    // 
    const updateCarouselHeight = (nextSlide) => {
        if (carouselRef.current) {
            setTimeout(() => {
                let currentSlide = carouselRef.current.state.currentSlide;
                let slideIndex = nextSlide === 0 ? 0 : nextSlide || currentSlide;
                const height = carouselRef.current.containerRef.current.querySelectorAll('.tab-card-data')[slideIndex]?.offsetHeight;
                if (height) {
                    const extraHeightForDots = 30;
                    setCarouselHeight(height + extraHeightForDots);
                }
            }, 50); // delay required for this to work reliably
        }
    };
    
    // trigger height adjustment
    const isExpandComplete = isExpanded && isVisible && isFadeIn;
    const prevIsExpandComplete = React.useRef(false);
    if (prevIsExpandComplete.current != isExpandComplete) {
        prevIsExpandComplete.current = isExpandComplete;
        updateCarouselHeight();
    }
    const isShrinkComplete = !isExpanded && !isVisible && !isFadeIn;
    const prevIsShrinkComplete = React.useRef(true); // initially shrunk
    if (prevIsShrinkComplete.current != isShrinkComplete) {
        prevIsShrinkComplete.current = isShrinkComplete;
        updateCarouselHeight();
    }
    const prevArgs = React.useRef({});
    if (prevArgs.current.topYearProjection != topYearProjection) {
        prevArgs.current.topYearProjection = topYearProjection;
        setTimeout(() => updateCarouselHeight(), 10); // delay needed for this to work
    }
    if (prevArgs.current.topIncludedIntroBonus != topIncludedIntroBonus) {
        prevArgs.current.topIncludedIntroBonus = topIncludedIntroBonus;
        setTimeout(() => updateCarouselHeight(), 10); // delay needed for this to work
    }
    if (prevArgs.current.selectedCategories != JSON.stringify(selectedCategories)) {
        prevArgs.current.selectedCategories = JSON.stringify(selectedCategories);
        setTimeout(() => updateCarouselHeight(), 10); // delay needed for this to work
    }
    if (prevArgs.current.selectedFeatures != JSON.stringify(selectedFeatures)) {
        prevArgs.current.selectedFeatures = JSON.stringify(selectedFeatures);
        setTimeout(() => updateCarouselHeight(), 10); // delay needed for this to work
    }
    if (prevArgs.current.cardIds != JSON.stringify(getCardsList().map(el => el.id))) { // cards changed
        prevArgs.current.cardIds = JSON.stringify(getCardsList().map(el => el.id));
        setTimeout(() => updateCarouselHeight(), 10); // delay needed for this to work
        // if card length is less than current slide index, go to last slide
        if (carouselIndexRef.current && getCardsList().length <= carouselIndexRef.current) {
            carouselRef.current.goToSlide(getCardsList().length-1);
        }
    }
    
    //
    React.useEffect(() => {
        window.addEventListener('resize', () => {
            updateCarouselHeight();
        });
        window.addEventListener('orientationchange', () => {
            updateCarouselHeight();
        });
    }, []);
    
    const isSecondYear = topYearProjection === secondYear()
    const isLastYear = topYearProjection === lastYear()
    
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1300 },
            items: 1,
            partialVisibilityGutter: 0,
        },
        tablet: {
            breakpoint: { max: 1300, min: 991 },
            items: 1,
            partialVisibilityGutter: 0,
        },
        mobile: {
            breakpoint: { max: 991, min: 0 },
            items: 1,
            partialVisibilityGutter: 0,
        }
    };
    

    return (
        
        <div className="top-carousel-root" style={{ height: carouselHeight }}>
            <Carousel
                ref={carouselRef}
                ssr
                partialVisible={true}
                deviceType={"desktop"}
                customTransition="all 0.3s cubic-bezier(0.16, 1, 0.3, 1)"
                responsive={responsive}
                itemClass="tab-card-data-container"
                showDots={true}
                renderDotsOutside={true}
                beforeChange={(nextSlide) => {
                    updateCarouselHeight(nextSlide);
                    carouselIndexRef.current = nextSlide;
                }}
            >
                {(getCardsList().slice(0, 10) || []).map((card, index) => {
                    
                    const {
                        cardId,
                        money,
                        points,
                        introMoney,
                        introPoints,
                        totalMoney,
                        calculation = {},
                        name = "",
                        stat = [],
                        link = "",
                        cardInfo = "",
                        features = [],
                        rewardInfoLong = "",
                        rewardRate = "",
                        annualFee = "",
                        creditRange = [],
                        introOffer = "",
                        introOfferGeneralDetail = [],
                        moreDetailInfo = [],
                        moreDetailNotes = [],
                        unitForPoints = "",
                        otherRewards = "",
                        totalMoneyPerYear = "",
                        introOfferDetail = [],
                        annualFeeDisplay = [],
                        annualFeeDetail = [],
                        rewardRateGeneralDetail = [],
                        annualFeeGeneralDetail = [],
                        rewardDetail = []
                    } = card || {};
                    const { isReward, isIntro, isAnnual } = calculation || {};
                    
                    const cardDetailsByGraphObj = {
                        stat, money, avgSpending, spending, onGetDate, selectedDate, topYearProjection,
                        rewardInfoLong, features, otherRewards, annualFeeDisplay, annualFeeGeneralDetail, 
                        creditRange, rewardRate, rewardRateGeneralDetail, introOffer, introOfferGeneralDetail
                    };
                    
                    // 
                    return (
                        <div className="tab-card-data" key={index.toString()}>
                            
                            {/* luci's pick ribbon */}
                            {index === 0 ?
                                <div className="top-pick">
                                    <span>luci's pick</span>
                                </div> : null
                            }
                            
                            {/* card name */}
                            <h4 className="slider-card-title">{name}</h4>
                            
                            {/* */}
                            <div className="card-first" style={{margin: "15px 0px"}}>
                            
                                {/* card image */}
                                <div className="t-card-img">
                                    <img
                                        className="card-image cursor-pointer" src={getCardImageURL(cardId)}
                                        onClick={() => window.open(link, "_blank")}
                                    />
                                </div>
                                
                                {/* card descrition (desktop only) */}
                                <div className="t-card-name">
                                    {cardInfo || ''}
                                </div>
                                
                                {/* apply button */}
                                <div className="t-card-action mt-4">
                                    <button
                                        className={`btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 mb-3 ga-ccApply ga-ccApply-section1 ga-ccApply-cardId${cardId} ga-ccApply-slidepos${index+1}`}
                                        type="submit"
                                        onClick={() => window.open(link, "_blank")}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                            
                            {/* card details area (desktop) */}
                            <div className={`card-points card-reward-xs ${topIncludedIntroBonus ? "" : "not-included-intro-bonus"}`}>
                                <div className="card-r ml-5">
                                    <span className={`rft ${!isReward ? "color-gray" : ""}`}>
                                        Reward from Transactions
                                     </span>
                                    <div className="d-flex a-center">
                                        <span className={`font-big ${!isReward ? "color-gray" : ""}`}>${valueWithCommas(money || 0)}</span>
                                        { !!(rewardDetail || []).length &&
                                            <Tooltip
                                                title={getToolTipConstant(rewardDetail || [])}
                                                trigger={["hover", "click"]}
                                            >
                                                <img className="gray-help" src={Help} style={!isReward ? {opacity: '0.5'} : null}/>
                                            </Tooltip>
                                        }
                                    </div>
                                    {
                                        points ?
                                            <small className={!isReward ? "color-gray" : ""}>{`(${commaSeparator(points || 0)} Points)`}</small> :
                                            (introPoints || unitForPoints || isSecondYear || isLastYear) ?
                                            <small className="invisible">Reward</small> : null
                                    }
                                </div>

                                {
                                    topIncludedIntroBonus ?
                                        <>
                                            <div className={`card-img-r`}>
                                                <img src={Plus} style={(!isReward || !isIntro) ? {opacity: '0.5'} : null}/>
                                            </div>
                                            <div className={`card-bonus`}>
                                                <span className={`rft ${!isIntro ? "color-gray" : ""}`}>
                                                    Intro Bonus
                                                </span>
                                                <div className="d-flex a-center">
                                                    <span className={`font-big ${!isIntro ? "color-gray" : ""}`}>${valueWithCommas(introMoney || 0)}</span>
                                                    { !!(introOfferDetail || []).length &&
                                                    <Tooltip
                                                        title={getToolTipConstant(introOfferDetail || [])}
                                                        trigger={["hover", "click"]}
                                                    >
                                                        <img className="gray-help" src={Help} style={!isIntro ? {opacity: '0.5'} : null}/>
                                                    </Tooltip>
                                                    }
                                                </div>
                                                { (introPoints || unitForPoints) ?
                                                    <small className={!isIntro ? "color-gray" : ""}>
                                                        {`(${commaSeparator(introPoints || 0)} ${unitForPoints})`}
                                                    </small> :
                                                    (points || isSecondYear || isLastYear) ?
                                                        <small className="invisible">Intro Bonus</small> : null
                                                }
                                            </div>
                                        </> : null
                                }

                                <div className="card-img-r">
                                    <img src={Minus} style={!isAnnual ? {opacity: '0.5'} : null}/>
                                </div>
                                <div className="card-fee">
                                    <span className={`rft ${!isAnnual ? "color-gray" : ""}`}>
                                        Annual Fee
                                    </span>
                                    <div className="d-flex a-center">
                                        <span className={`font-big ${!isAnnual ? "color-gray" : ""}`}>${annualFee}</span>
                                        { !!(annualFeeDetail || []).length &&
                                            <Tooltip
                                                title={getToolTipConstant(annualFeeDetail || [])}
                                                trigger={["hover", "click"]}
                                            >
                                                <img className="gray-help" src={Help} style={!isAnnual ? {opacity: '0.5'} : null}/>
                                            </Tooltip>
                                        }
                                    </div>
                                    { (introPoints || unitForPoints || points || isSecondYear || isLastYear) ? <small className="invisible">Annual Fee</small> : null }
                                </div>
                                <div className="card-img-r">
                                    <img src={Equal}/>
                                </div>
                                <div className="card-reward">
                                    <span className="rft">Total Reward</span>
                                    <span className="font-big">${valueWithCommas(totalMoney || 0)}</span>
                                    { topYearProjection === firstYear() ?
                                        (introPoints || unitForPoints || points) ?
                                            <><br/> <small className="invisible">Total Reward</small></> : null :
                                        <><br/> <small>{`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}</small></>
                                    }
                                </div>
                            </div>
                            
                            {/* card details area (mobile) */}
                            <div className="mob-card-table card-detail-mobile-section m-0 mt-2">
                                <div className="mc-detail card-features mb-0">
                                
                                    {/* total rewards */}
                                    <div className="total-rewards">
                                        <div className="d-flex justify-content-center">
                                            <h4 className="mb-0 d-flex" style={{columnGap: 6, alignItems: "flex-start"}}>
                                                <b>Total Rewards</b>
                                                <div className={"d-flex align-items-center ml-1 value"} style={{flexDirection: "column"}}>
                                                    <div>${valueWithCommas(totalMoney || 0)}</div>
                                                    { topYearProjection !== firstYear() ?
                                                        <div className="fs-12">
                                                            {`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    
                                    {/* total reward details */}
                                    <div className="card-left mob-card-left" style={{fontSize: "14px"}}>
                                        
                                        {/* rewards from trx */}
                                        <div className="cl-item d-flex">
                                            <img className="w-10 mb-auto mr-2" src={Plus} style={{marginTop: "7px"}}/>
                                            <div>
                                                <span className={`cl-t`}>Reward Value</span>
                                                <div className="cl-info">
                                                    <div className="mr-2">
                                                        ${valueWithCommas(money || 0)}
                                                    </div>
                                                    { !!(rewardDetail || []).length &&
                                                        <Tooltip
                                                            title={getToolTipConstant(rewardDetail || [])}
                                                            trigger={["hover", "click"]}
                                                        >
                                                            <img className="gray-help" src={Help}/>
                                                        </Tooltip>
                                                    }
                                                </div>
                                                { !!points && <small>{`(${commaSeparator(points || 0)} Points)`}</small> }
                                            </div>
                                        </div>
                                        
                                        {/* rewards from intro bonus */}
                                        {topIncludedIntroBonus ?
                                            <div className="cl-item d-flex">
                                                <img className="w-10 mb-auto mr-2" src={Plus} style={{marginTop: "7px"}}/>
                                                <div>
                                                    <span className={`cl-t`}>Intro Bonus</span>
                                                    <span className="cl-info">
                                                        <div className="mr-2">
                                                            ${valueWithCommas(introMoney || 0)}
                                                        </div>
                                                        { !!(introOfferDetail || []).length &&
                                                            <Tooltip
                                                                title={getToolTipConstant(introOfferDetail || [])}
                                                                trigger={["hover", "click"]}
                                                            >
                                                                <img className="gray-help" src={Help}/>
                                                            </Tooltip>
                                                        }
                                                    </span>
                                                    { (introPoints || unitForPoints) ?
                                                        <small>
                                                            {`(${commaSeparator(introPoints || 0)} ${unitForPoints})`}
                                                        </small>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }
                                        
                                        {/* annual fee */}
                                        <div className="cl-item d-flex">
                                            <img className="w-10 mb-auto mr-2" src={Minus} style={{marginTop: "12px"}}/>
                                            <div>
                                                <span className={`cl-t`}>Annual Fee</span>
                                                <span className="cl-info">
                                                    <div className="mr-2">
                                                        ${commaSeparator(toFixedToDecimal(annualFee, 2))}
                                                    </div>
                                                    { !!(annualFeeDetail || []).length &&
                                                        <Tooltip
                                                            title={getToolTipConstant(annualFeeDetail || [])}
                                                            trigger={["hover", "click"]}
                                                        >
                                                            <img className="gray-help" src={Help}/>
                                                        </Tooltip>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        
                                        {/* reward rate */}
                                        <div className="cl-item d-flex">
                                            <img className="w-10 mb-auto mt-10 mr-1 hidden-visibility" src={Minus} />
                                            <div>
                                                <span className="cl-t">Reward Rate</span>
                                                <span className="cl-info">
                                                    <div className="mr-2">
                                                        {rewardRate}
                                                    </div>
                                                    { !!(rewardRateGeneralDetail || []).length &&
                                                        <Tooltip
                                                            title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                                            trigger={["hover", "click"]}
                                                        >
                                                            <img className="gray-help" src={Help}/>
                                                        </Tooltip>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            {/* CardDetailsByGraph used to be here, now moved to SeeMoreDetails */}
                            
                            {/* details */}
                            <SeeMoreDetails 
                                {...{
                                    isExpanded,
                                    isVisible,
                                    isFadeIn,
                                    setIsExpanded,
                                    setIsVisible,
                                    setIsFadeIn,
                                    moreDetailInfo,
                                    moreDetailNotes,
                                    cardDetailsByGraphObj
                                }}
                            />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default CardDetailsCarousel;
