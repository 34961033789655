import React from "react";
import { Tooltip, Divider } from "antd";
import MediaQuery from "react-responsive";
import cloneDeep from "lodash/cloneDeep";
import { PieChart } from "react-minimal-pie-chart";
import {
  commaSeparator,
  valueWithCommas,
  getCategoriesIcon,
  getCategoriesColor,
  getToolTipConstant,
  firstYear,
  secondYear,
  lastYear
} from "../../../../../../utils/constants"
import LineBarChart from "./LineBarChart";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";


class CardDetailsByGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewBy: "reward"
    };
  }

  onChange = type => {
    this.setState({
      viewBy: type
    });
  };

  getChartData = () => {
    return (this.onGetStats() || []).map((s, i) => {
      return {
        ...s,
        color: getCategoriesColor(s.category),
        title: s.category,
        value: s.spending,
        percentage: s.spending/this.onGetTotalSpending()*100+'%'
      };
    });
  };

  onGetStats = () => {
    const { stat = [] } = this.props
    let array = cloneDeep(stat || [])

    const index = array.findIndex(x => x.category === "Other")

    if(index !== -1) {
      array.splice(index, 1)
      array = array.sort((a,b) => b.spending - a.spending)
      array.push(stat[index])
    } else {
      array = array.sort((a,b) => b.spending - a.spending)
    }

    return array
  }

  onGetTotalSpending = () => {
    let total = 0

    this.onGetStats().forEach(spend => {
      total += spend.spending
    })

    return total
  }

  render() {
    
    const { onGetDate, money, avgSpending, spending, selectedDate, topYearProjection } = this.props;
    const { viewBy } = this.state;

    return (
      <div className="data-graph">
      
        {/* title */}
        <div className="dg-title reward-category">
          Breakdown by Reward Categories
        </div>
        
        {/*  */}
        <div className="dg-area" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

          {/*  */}
          <div className="graph-info mt-0">
          
            {/* pie graph */}
            <MediaQuery minWidth={567}>
              <div className="graph-left mt-3">
              
                {/* year projetion label */}
                <div className="dg-dates">
                  <div className="dg-date">{topYearProjection === firstYear() ? "1-Year Projection" : topYearProjection === secondYear() ? "2-Year Projection" : topYearProjection === lastYear() ? "3-Year Projection" : ""}</div>
                </div>
              
                {/* chart area */}
                <div className="chart-area mt-0">
                  <div className="d-flex align-items-center justify-content-center pens flex-grow-1 position-relative">
                    <PieChart
                        animation
                        animationDuration={500}
                        animationEasing="ease-out"
                        center={[50, 50]}
                        rounded={false}
                        data={this.getChartData()}
                        labelPosition={50}
                        lengthAngle={360}
                        lineWidth={15}
                        paddingAngle={0}
                        radius={50}
                        startAngle={0}
                        viewBoxSize={[100, 100]}
                        style={{
                          height: "250px",
                          position: "relative",
                          transform: "rotate(-90deg)"
                        }}
                    />
                    <div className="chart-inner-text d-flex flex-column">
                      <div>
                        <h3 className="ci-title">${valueWithCommas(this.onGetTotalSpending() || 0)}</h3>
                      </div>
                      <div>
                        <span className="ci-value">Total Spending</span>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <h3 className="ci-title reward-color">${valueWithCommas(money || 0)}</h3>
                      </div>
                      <div>
                        <span className="ci-value reward-color">Reward from Txs</span>
                      </div>
                    </div>
                  </div>
                  <div className="avg-spending-month">
                    Avg Spending per Month:
                    <b className="ml-1">${valueWithCommas(avgSpending || 0)}</b>
                    {
                      selectedDate === 6 ?
                          <Tooltip
                              title="Assumes 30 days in a month"
                              trigger={"click"}
                          >
                            <img className="help-icon ml-1" src={Help}/>
                          </Tooltip> : null
                    }
                  </div>
                </div>
              </div>
            </MediaQuery>
            
            {/* line graph */}
            <MediaQuery maxWidth={567}>
              <LineBarChart
                  getChartData={this.getChartData}
                  onGetTotalSpending={this.onGetTotalSpending}
                  money={money}
              />
            </MediaQuery>
            
            {/* data table */}
            <div className="graph-right n-p-mobile pt-0">
              <div className="gr-item title">
                <span className="gr-name">
                  <span>&nbsp;</span> &nbsp;
                </span>
                <span className="gr-spending">Spending</span>
                { viewBy === "reward" ? <span className="gr-reward">Reward</span> : null }
              </div>
              {(this.getChartData() || []).map((x, index) => (
                <div className="gr-item" key={index.toString()}>
                  <span className="gr-name">
                    <img src={getCategoriesIcon(x.category)} alt={`${x.category}-icon`} style={{background: x.color}}/>
                    <div className="cat-name">
                      {x.category}
                    </div>
                    {
                      (x.tooltip || []).length ?
                        <Tooltip
                          title={getToolTipConstant(x.tooltip || [])}
                          trigger={["hover", "click"]}
                        >
                          <img className="gray-help ml-1" src={Help}/>
                        </Tooltip> : null
                    }
                  </span>
                  <span className="gr-spending">${valueWithCommas(x.spending || 0)}</span>
                  { viewBy === "reward" ?
                    <span className="gr-reward" style={!!x.points ? {paddingTop: 20} : null}>
                      <div>
                        ${valueWithCommas(x.money || 0)}
                      </div>
                      {!!x.points ? 
                        <div className="ml-1" style={{fontSize: "11px", bottom: "6px", position: "relative", lineHeight: "10px", marginTop: "4px"}}>
                          ({commaSeparator((x.points || 0))} Points)
                        </div>
                        : null
                      }
                    </span> : null
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardDetailsByGraph;
