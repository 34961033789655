import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {cloneDeep} from "lodash";
import Header from "../Header";
import Sidebar from "../Sidebar";
// import SecondStepBenchmark from "./Components/SecondStepBenchmark";
import { ApiService } from "../../../services/ApiService";
import {setAccounts, setReLoginRequiredInstitutes} from "../../../redux/modules/accounts/accountsActions";
import { setCosts } from "../../../redux/modules/manualCosts/costsActions";
import { fetchCards, setCards } from "../../../redux/modules/cards/cardsActions";
import { setDashboardStep } from "../../../redux/modules/dashboardStep/dashboardStepActions";
import {setResultSetting} from "../../../redux/modules/resultSetting/resultSettingActions";
import {
  getStoredAccounts,
  getStoredCosts,
  removeComma,
  getDateQuery,
  getDateRanges,
  modifyCardsList,
  isUserLoggedIn,
  getStoredCards,
  getManualStoredValue,
  COSTS_BENCHMARK,
  CURRENT_YEAR,
  CURRENT_MONTH_SHORT,
  CURRENT_MONTH_LONG,
  FILTER_CARDS,
  CONTEXT_ARRAY,
  GET_CONTEXT_VARIABLES
} from "../../../utils/constants";
import "../../../common/styles/dashboard.scss";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";

import { Divider } from "antd";

import { Loading } from '../../../common/components/Loading/Loading';
import CardDetailsCarousel from "../Dashboard/Components/SecondStep/component/CardDetailsCarousel"
import FilterSettings from "../Dashboard/Components/SecondStep/component/FilterSettings";
import HorizontalCardsList from "../Dashboard/Components/SecondStep/component/RecommendedCards/component/HorizontalCardsList"
import CardDetailsModal from "../Dashboard/Components/SecondStep/component/CardDetailsModal"
import {Link} from "react-router-dom";





/**
 *
 *
 */
const BestCardsFor = (args) => {
  
  // 
  const { 
    match, 
    onFetchCards, 
  } = args || {};
  
  // params
  const { params } = match || {};
  
  // 
  const { context } = params || {};
  
  // 
  const [isFindingCards, setIsFindingCards] = useState(false);
  const [isOops, setIsOops] = useState(false);
  
  // 
  const [cardDetailsModalData, setCardDetailsModalData] = useState(null); // if not null, opens modal window
  const [fetchResults, setFetchResults] = useState(null);
  const prevContextRef = useRef(null);
  
  // fixed constants for top carousel
  const topYearProjection = "1";
  const topIncludedIntroBonus = GET_CONTEXT_VARIABLES(context).isIncludeIntroBonus;
  
  // filters for mid carousel
  const [filterYearProjection, setFilterYearProjection] = useState(topYearProjection);
  const [filterIncludedIntroBonus, setFilterIncludedIntroBonus] = useState(topIncludedIntroBonus);
  const [filterSelectedCategories, setFilterSelectedCategories] = useState(GET_CONTEXT_VARIABLES(context).selectedCategories);
  const [filterSelectedFeatures, setFilterSelectedFeatures] = useState(GET_CONTEXT_VARIABLES(context).selectedFeatures);
  
  // check if fetch needed
  useEffect(() => {
    if (prevContextRef.current != context) {
      
      // set loading state
      setIsFindingCards(true);
      
      // costs
      const { costs } = GET_CONTEXT_VARIABLES(context) || {};
      const { grocery, gas, restaurant, other, recreation, travel } = costs || {};
      
      // dates
      const dateRange = getDateRanges(1);
      const dateQuery = `&startDate=${moment((dateRange || [])[0]).format("YYYY-MM-DD")}&endDate=${moment((dateRange || [])[1]).format("YYYY-MM-DD")}&nMonths=${12}`;
      
      // query
      const query = `?1=${removeComma(grocery)}&2=${removeComma(gas)}&4=${removeComma(restaurant)}&5=${removeComma(other)}&8=${removeComma(recreation)}&9=${removeComma(travel)}${dateQuery}`;

      // fetch
      onFetchCards({
        apiEndpoint: "free/run-backtest-manual",
        query: query,
        onSuccess: (res) => {
          setFetchResults(res);
          setIsFindingCards(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
        },
        onFail: (response) => {
          setIsOops(true);
          setIsFindingCards(false);
        }
      });
      
      // reset filters
      const contextVars = GET_CONTEXT_VARIABLES(context);
      setFilterSelectedCategories(contextVars.selectedCategories);
      setFilterSelectedFeatures(contextVars.selectedFeatures);
      
      // update ref
      prevContextRef.current = context;
    }
  }, [context, onFetchCards]);
  
  
  /**
   *
   *
   */
  const getCardsList = ({ isIntroBonus, yearProj, selectedCats, selectedFeats }) => {
    
    // handle empty
    if (!fetchResults) {
      return [];
    }
    
    // intro bonus included?
    const r = isIntroBonus? fetchResults.results : fetchResults.noBonus_results;
    
    // projection years?
    const resultsList = _.cloneDeep(r?.[yearProj] || []);
    
    // this modify combines cards with results
    const cardList = modifyCardsList([...resultsList], fetchResults.cards || {});
    
    // filter
    let filteredList = cardList;
    if ((selectedCats || []).length || (selectedFeats || []).length) {
      filteredList = FILTER_CARDS(cardList, selectedCats, selectedFeats);
    }
    
    // sort
    const sortedList = _.orderBy(filteredList, ["totalMoney", 'cardName'], "desc");
    
    // 
    return sortedList;
  }
  
  // 
  const {
    headlineFor,
    firstParagraph,
    secondParagraph,
    selectedFeatures: topSelectedFeatures,
    selectedCategories: topSelectedCategories,
    costs,
  } = GET_CONTEXT_VARIABLES(context) || {};
  
  
  //
  const avgSpending = fetchResults?.avgSpending || 0;
  const spending = fetchResults?.spending || 0;

  //
  const isLoading = isFindingCards;

  // render
  return (
    <div className="dashboard-wrapper">

      <Header/>
      
      <section>
        <div className="wrapper profile-sidebar">
          <div id="content" className="">
          
            <div className="card luci-card second-step">
            
              <div className="card-body" style={{padding: "0px 5px"}}>
              
                {/* title */}
                <h1 className="luci-title" style={{fontSize: "26px", letterSpacing: "-0.2px"}}>
                  {"Best Credit Cards for " + headlineFor}
                  <span style={{display: "inline-block"}}>
                    &nbsp;-&nbsp;{CURRENT_MONTH_SHORT} {CURRENT_YEAR}
                  </span>
                </h1>
                
                {/* paragraphs */}
                <div className="mb-1">
                  {firstParagraph}
                </div>
                <div className="mb-1">
                  {secondParagraph}
                </div>
              </div>
              
              
              <div className="card-body padd-xs">
              
                {isLoading ?
                  <div className="text-center mt-100"><Loading isInteral={true} isCenter={true}/></div>
                  :
                  <>
                  
                    {/* card details */}
                    <CardDetailsCarousel {...{
                      getCardsList: () => {
                        return _.take(getCardsList({
                          isIntroBonus: topIncludedIntroBonus, 
                          yearProj: topYearProjection, 
                          selectedCats: topSelectedCategories, 
                          selectedFeats: topSelectedFeatures,
                        }), 3);
                      },
                      avgSpending,
                      spending,
                      topYearProjection,
                      topIncludedIntroBonus,
                      selectedCategories: topSelectedCategories,
                      selectedFeatures: topSelectedFeatures,
                    }}/>
                    
                    {/* divider */}
                    <Divider style={{marginTop: 25, marginBottom: 30}}/>
                    
                    {/* dropdown filter settings (new) */}
                    <FilterSettings {...{
                      selectedCategories: filterSelectedCategories,
                      selectedFeatures: filterSelectedFeatures,
                      onDismissFilter: () => {
                        setFilterYearProjection("1");
                        setFilterIncludedIntroBonus(true);
                        setFilterSelectedCategories([]);
                        setFilterSelectedFeatures([]);
                      },
                      onFilterChange: (type, value) => {
                        let list = [];
                        if (type == "selectedCategories") {
                          list = _.cloneDeep(filterSelectedCategories);
                        } else if (type == "selectedFeatures") {
                          list = _.cloneDeep(filterSelectedFeatures);
                        }
                        if((list || []).includes(value)) {
                          list = (list || []).filter(x => x !== value)
                        } else {
                          list.push(value)
                        }
                        if (type == "selectedCategories") {
                          setFilterSelectedCategories(list);
                        } else if (type == "selectedFeatures") {
                          setFilterSelectedFeatures(list);
                        }
                      },
                      isIncludeIntroBonus: filterIncludedIntroBonus,
                      onIncludeIntroChange: () => { setFilterIncludedIntroBonus(!filterIncludedIntroBonus); },
                      yearProjection: filterYearProjection,
                      onYearProjectionChange: (yr) => { setFilterYearProjection(yr); },
                    }}/>
                    
                    {/* small carousel */}
                    <HorizontalCardsList {...{
                      onSelectCard: (card) => {
                        setCardDetailsModalData(card);
                      }, 
                      cardList: getCardsList({
                          isIntroBonus: filterIncludedIntroBonus, 
                          yearProj: filterYearProjection, 
                          selectedCats: filterSelectedCategories, 
                          selectedFeats: filterSelectedFeatures,
                      }),
                      bottomYearProjection: filterYearProjection,
                      bottomIncludedIntroBonus: filterIncludedIntroBonus,
                    }}/>
                    
                    {/* divider */}
                    <Divider style={{marginTop: 25, marginBottom: 30}}/>
                    
                    {/* navigation buttons */}
                    <div>
                      <div style={{fontSize: "26px", fontWeight: "bold", lineHeight: "26px", padding: "0px 5px"}}>
                        Find best credit cards for...
                      </div>
                      <div className="mt-4 mb-1" style={{display: "flex", flexWrap: "wrap", columnGap: "10px", rowGap: "15px", justifyContent: "flex-start"}}>
                        {_.map(CONTEXT_ARRAY, el => {
                          const contextVars = GET_CONTEXT_VARIABLES(el);
                          if (el == context) { return; }
                          return (
                            <Link to={`/best-credit-cards/${el}`} key={el}>
                              <button className="btn luci-btn white-btn" style={{whiteSpace: "nowrap", fontSize: "16px"}}>
                                {contextVars.headlineFor}
                              </button>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    
                    {/* divider */}
                    <Divider style={{marginTop: 25, marginBottom: 30}}/>
                  </>
                }
              </div>
            </div>
            
            {/* card details modal */}
            {cardDetailsModalData ?
              <CardDetailsModal
                selectedCardInfo={cardDetailsModalData || {}}
                onCancel={() => setCardDetailsModalData(null)}
              />
              : null
            }
            
            {/* error message */}
            {isOops?
              <div className="mt-60 text-center w-100">
                <h3>{ "Oops, Something went wrong. Please try again later." }</h3>
              </div>
              : null
            }
          </div>
        </div>
      </section>
    </div>
  );
}


const mapStateToProps = state => {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchCards: data => {
      dispatch(fetchCards(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(BestCardsFor);
