import React from "react"
import {Tooltip} from "antd";
import cloneDeep from "lodash/cloneDeep";
import Carousel from "react-multi-carousel";
import {
    commaSeparator,
    valueWithCommas,
    getCardImageURL,
    getCategoriesColor,
    getCategoriesIcon,
    getToolTipConstant,
    firstYear,
} from "../../../../../../../../utils/constants";
import "react-multi-carousel/lib/styles.css";
import Help from "../../../../../../../../assets/images/dashboard/help-icon.svg";
import Plus from "../../../../../../../../assets/images/dashboard/plus.svg";
import Minus from "../../../../../../../../assets/images/dashboard/minus.svg";

class HorizontalCardsList extends React.Component {
    constructor(props){
        super(props)
        this.state = {slideIndex: 0}
    }

    getChartData = (stat) => {
        return (this.onGetStats(stat) || []).map((s, i) => {
            return {
                ...s,
                color: getCategoriesColor(s.category),
                title: s.category,
                value: s.spending
            };
        });
    };

    onGetStats = (stat) => {
        let array = cloneDeep(stat || [])

        const index = array.findIndex(x => x.category === "Other")

        if(index !== -1) {
            array.splice(index, 1)
            array = array.sort((a,b) => b.spending - a.spending)
            array.push(stat[index])
        } else {
            array = array.sort((a,b) => b.spending - a.spending)
        }

        return array
    }

    getContent = (stat) => {
        return (
            <div className="graph-info">
                <div className="graph-right n-p-mobile">
                    <div className="gr-item title">
                        <span className="gr-name">
                          <span>&nbsp;</span> &nbsp;
                        </span>
                        <span className="gr-spending">Spending</span>
                        <span className="gr-reward">Reward</span>
                    </div>
                    {(this.getChartData(stat) || []).map((x, index) => (
                        <div className="gr-item" key={index.toString()}>
                          <span className="gr-name">
                            <img src={getCategoriesIcon(x.category)} alt={`${x.category}-icon`} style={{background: x.color}}/>
                            <span className="cat-name">
                              {x.category}
                                {
                                  (x.tooltip || []).length ?
                                    <Tooltip
                                        title={getToolTipConstant(x.tooltip || [])}
                                        trigger={"click"}
                                    >
                                        <img className="w-25 cursor-pointer" src={Help}/>
                                    </Tooltip> : null
                                }
                            </span>
                          </span>
                            <span className="gr-spending">${valueWithCommas(x.spending || 0)}</span>
                            <span className="gr-reward" style={!!x.points ? {paddingTop: 20} : null}>
                                ${valueWithCommas(x.money || 0)} <br />
                                { !!x.points ? <small className="ml-1">({commaSeparator((x.points || 0))} Points)</small> : null }
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }


    // 
    render() {
        const { cardList, onSelectCard, selectedCategory, bottomIncludedIntroBonus, bottomYearProjection } = this.props
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1300 },
                items: 3,
                partialVisibilityGutter: 0,
            },
            tablet: {
                breakpoint: { max: 1300, min: 991 },
                items: 2,
                partialVisibilityGutter: 0,
            },
            mobile: {
                breakpoint: { max: 991, min: 0 },
                items: 1,
                partialVisibilityGutter: 0,
            }
        };
        
        // 
        return (
          <div className="carousel-root">
            <Carousel
                ssr
                partialVisible={true}
                deviceType={"desktop"}
                itemClass="card-item-container"
                responsive={responsive}
                customTransition="all 0.3s cubic-bezier(0.16, 1, 0.3, 1)"
                beforeChange={(nextSlide) => {this.setState({slideIndex: nextSlide});}}
            >
                {(cardList || []).map((card, index) => {
                    const { money, points, introMoney, introPoints, name = "", rewardDetail = [], tooltips = [], annualFee = "", annualFeeDetail = [], introOfferDetail = [], link = "", totalMoney = "", totalMoneyPerYear = "" } = card || {}
                    
                    return (
                        
                        <div className={"card-item"} key={index.toString()}>
                            
                            {/* category label */}
                            {selectedCategory? 
                                <>
                                    <div className="card-top-section"/>
                                    {index === 0 ?
                                        <div className="top-pick">
                                            <span>luci's pick</span>
                                        </div>
                                        : null
                                    }
                                    <div className="card-top">
                                        <img src={getCategoriesIcon(selectedCategory)} alt={`${card.category}-icon`} style={{background: getCategoriesColor(selectedCategory)}}/>
                                        <span className="c-name">{card?.shortDescription || ''}</span>
                                        {
                                            (tooltips || []).length ?
                                                <Tooltip
                                                    title={getToolTipConstant(tooltips || [])}
                                                    trigger={"click"}
                                                >
                                                    <img className="w-25 cursor-pointer" src={Help}/>
                                                </Tooltip> : null
                                        }
                                    </div>
                                </>
                                : null
                            }

                            {/* card image */}
                            <div className="text-center mb-1 mt-4" style={{minHeight: "135px"}}>
                                <img
                                    className="card-image cursor-pointer"
                                    src={getCardImageURL(card.cardId)}
                                    onClick={() => window.open(link, "_blank")}
                                />
                            </div>

                            {/* card name */}
                            <div className={"small-slider-card-title"}>
                                {name}
                            </div>

                            {/* reward details */}
                            <div className="tab-card-data mt-3">
                            
                                {/* total reward */}
                                <div className="total-rewards">
                                    <div className="d-flex justify-content-center">
                                        <h4 className="mb-0 d-flex" style={{columnGap: 6, alignItems: "flex-start"}}>
                                            <b>Total Rewards</b>
                                            <div className={"d-flex align-items-center ml-1 value"} style={{flexDirection: "column"}}>
                                                <div>${valueWithCommas(totalMoney || 0)}</div>
                                                { bottomYearProjection !== firstYear() ?
                                                        <div className="fs-12">
                                                            {`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                
                                {/* reward details */}
                                <div className="card-points">
                                    <div className="card-r">
                                         <div className="mt-2 text-nowrap d-flex align-items-center">
                                             <div className="card-img-r mr-2">
                                                 <img src={Plus} />
                                             </div>
                                             <b style={{whiteSpace: "nowrap"}}>Reward Value</b>
                                             <span className="s-text ml-2 mr-2">
                                                 ${valueWithCommas(money || 0)}
                                                 {points ? ` (${commaSeparator(points || 0)} Points)` : null}
                                             </span>
                                             { !!(rewardDetail || []).length &&
                                                <Tooltip 
                                                    getPopupContainer={trigger => trigger.parentElement} 
                                                    title={this.getContent(card.stat)} 
                                                    trigger={["hover", "click"]} 
                                                    overlayClassName="over-popup"
                                                >
                                                     <img className="gray-help" src={Help} />
                                                 </Tooltip>
                                             }
                                         </div>

                                        {
                                            bottomIncludedIntroBonus ?
                                                <div className="mt-2 d-flex align-items-center">
                                                    <div className="card-img-r mr-2">
                                                        <img src={Plus} />
                                                    </div>
                                                    <b style={{whiteSpace: "nowrap"}}>Intro Bonus</b>
                                                    <span className="text-nowrap ml-2 mr-2">
                                                        ${valueWithCommas(introMoney || 0)}
                                                        {introPoints ? ` (${commaSeparator(introPoints || 0)} Points)` : null}
                                                    </span>
                                                    { !!(introOfferDetail || []).length &&
                                                        <Tooltip
                                                            overlayClassName="notes-over-popup"
                                                            title={getToolTipConstant(introOfferDetail || [])}
                                                            trigger={["hover", "click"]}
                                                        >
                                                            <img className="gray-help" src={Help} />
                                                        </Tooltip>
                                                    }
                                                </div> : null
                                        }

                                        <div className="mt-2 d-flex align-items-center">
                                            <div className="card-img-r mr-2">
                                                <img src={Minus} />
                                            </div>
                                            <b style={{whiteSpace: "nowrap"}}>Annual Fee</b>
                                            <span className="text-nowrap ml-2 mr-2">
                                                ${valueWithCommas(annualFee || 0)}
                                            </span>
                                            { !!(annualFeeDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(annualFeeDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img className="gray-help" src={Help}/>
                                                </Tooltip>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            {/* buttons */}
                            <div className="text-center mt-4 mb-2">
                                <button
                                    className={`btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 mb-3 ga-ccApply ga-ccApply-section2 ga-ccApply-slidepos${index+1} ccApply-cardId${card.cardId}`}
                                    type="submit"
                                    onClick={() => window.open(link, "_blank")}
                                >
                                    Apply
                                </button> <br/>
                                <button
                                    className="btn luci-btn dark-btn shadow-none font-weight-bold pt-3 pb-3 mb-3"
                                    type="submit"
                                    data-toggle="modal"
                                    data-target="#exampleModal-a"
                                    onClick={() => onSelectCard(card)}
                                >
                                    Details
                                </button>
                            </div>

                        </div>
                    );
                })}
            </Carousel>
          </div>
        )
    }
}

export default HorizontalCardsList
