import React from "react"
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";
import { getNotesFromLuci } from "../../../../../../utils/constants"

// for CardDetailsByGraph
import CardDetailsByGraph from "../component/CardDetailsByGraph"
import { getFeaturesIcon, getToolTipConstant } from "../../../../../../utils/constants"
import Rate from "../../../../../../assets/images/dashboard/rate.svg";
import Prize from "../../../../../../assets/images/dashboard/prize.svg";
import { Tooltip, Divider } from "antd";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
const getOtherRewards = (otherObject) => {
    try {
        return JSON.parse(otherObject) || {}
    } catch (e) {
        return {}
    }
}



function SeeMoreDetails(args) {
    
    const {
        isExpanded, // if true, triggers transition
        isVisible, // if true, renders components
        isFadeIn, // if true, triggers fade in
        setIsExpanded,
        setIsVisible,
        setIsFadeIn,
        moreDetailInfo = [], 
        moreDetailNotes = [], 
        cardDetailsByGraphObj = {}
    } = args;
    
    // 
    const {
        stat, money, avgSpending, spending, onGetDate, selectedDate, topYearProjection,
        rewardInfoLong, features, otherRewards, annualFeeDisplay, annualFeeGeneralDetail, 
        creditRange, rewardRate, rewardRateGeneralDetail, introOffer, introOfferGeneralDetail
    } = cardDetailsByGraphObj;
    
    // do expand animation first, then show the expanded contents
    React.useEffect(() => {
        if (isExpanded) {
            // expand parent, then show child with delay
            const timeout = setTimeout(() => {
                setIsVisible(true);
                setTimeout(() => setIsFadeIn(true), 0);
            }, 0);
            return () => clearTimeout(timeout);
        } else {
            const timeout = setTimeout(() => {
                setIsVisible(false);
                setIsFadeIn(false);
            }, 0);
            return () => clearTimeout(timeout);
        }
    }, [isExpanded]);
    
    // 
    return(
        
        <div className={"see-more" + (isExpanded? " see-more-expanded" : "")}>
            
            {/* toggle */}
            <a
                style={{color: "#333333", textDecoration: "none"}}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded? "See Less Details" : "See More Details"}
                {isExpanded?
                    <img src={Arrow} className="arrow-tab-less ml-2"/>
                    :
                    <img src={Arrow} className="arrow-tab-more ml-2"/>
                }
            </a>
            
            {/* expanded content */}
            <div className={"expanded-details" + (isFadeIn? "" : " fading-in")} id="" style={{display: isVisible? null : "none"}}>
                <div className="card card-body card-detail-modal pt-2 pb-2 pl-1 pr-1">
                    <div>
                        
                        {/* divider */}
                        <Divider style={{marginTop: 0, marginBottom: 14}}/>
                        
                        {/* graph */}
                        <CardDetailsByGraph
                          stat={stat || []}
                          money={money}
                          avgSpending={avgSpending}
                          spending={spending}
                          onGetDate={onGetDate}
                          selectedDate={selectedDate}
                          topYearProjection={topYearProjection}
                        />
                        
                        {/* divider */}
                        <Divider style={{marginTop: 20, marginBottom: 15}}/>
                        
                        {/*  */}
                        <div className="card-features">
                            <div className="card-f-area">
                                
                                {/* Features */}
                                <div className="card-right">
                                    <div className="cr-title">
                                        Features & Other Rewards
                                    </div>
                                    <div className="cf-icons">
                                        {
                                            (features || []).map((feature, index) => (
                                              <div className="cfi-item child-feature" key={index.toString()}>
                                                  <img className="mr-2" src={getFeaturesIcon(feature) || Rate}/>
                                                  <span>{feature}</span>
                                              </div>
                                            ))
                                        }
                                        {
                                            (Object.keys(getOtherRewards(otherRewards)) || []).map((rewardKey, index) => (
                                              <div className="cfi-item child-feature" key={index.toString()}>
                                                  <img className="mr-2" src={Prize}/>
                                                  <span>
                                                      {rewardKey || ""}
                                                  </span>
                                                  { getOtherRewards(otherRewards)?.[rewardKey] &&
                                                      <Tooltip
                                                          title={getToolTipConstant([getOtherRewards(otherRewards)?.[rewardKey]] || [])}
                                                          trigger={["hover", "click"]}
                                                      >
                                                          <img className="gray-help ml-2" src={Help}/>
                                                      </Tooltip>
                                                  }
                                              </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                
                                {/* quick take */}
                                <div className="card-left">
                                    <div className="cr-title mb-1">
                                        Quick Take
                                    </div>
                                    <div className="cards">
                                        <div className="qt-item">
                                            <span className="qt-t">Annual Fee</span>
                                            <span className="qt-info">
                                                {(annualFeeDisplay || []).map((fee, index) => <span key={index.toString()}>{`${index === 0 ? fee : ` (${fee})`}`}</span>)}
                                                { !!(annualFeeGeneralDetail || []).length &&
                                                    <Tooltip
                                                        title={getToolTipConstant(annualFeeGeneralDetail || [])}
                                                        trigger={["hover", "click"]}
                                                    >
                                                        <img className="gray-help ml-1" src={Help}/>
                                                    </Tooltip>
                                                }
                                            </span>
                                        </div>
                                        <div className="qt-item">
                                            <span className="qt-t">Recommended Credit Score</span>
                                            <span className="qt-info">{creditRange.join(" - ")}</span>
                                        </div>
                                        <div className="qt-item">
                                            <span className="qt-t">Reward Rate</span>
                                            <span className="qt-info">
                                            {rewardRate}
                                            {!!(rewardRateGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                                    trigger={["hover", "click"]}
                                                >
                                                    <img className="gray-help ml-1" src={Help}/>
                                                </Tooltip>
                                            }
                                            </span>
                                        </div>
                                        <div className="qt-item">
                                            <span className="qt-t">Intro Offer</span>
                                            <span className="qt-info">
                                            {introOffer?.intro_description || ""}
                                            { !!(introOfferGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(introOfferGeneralDetail || [])}
                                                    trigger={["hover", "click"]}
                                                >
                                                    <img className="gray-help ml-1" src={Help}/>
                                                </Tooltip>
                                            }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                           
                        {/* divider */}
                        <Divider style={{marginTop: 5, marginBottom: 15}}/>
                        
                        {/* */}
                        <div className="card-dtl">
                            
                            {/* details*/}
                            <div className="card-dtl-l">
                                <div className="cr-title">
                                  Details
                                </div>
                                {(moreDetailInfo || []).map((reward, index) => (
                                    <div className="card-dtl-item" key={index.toString()}>
                                      <img src={Line} className="" style={{marginTop: "6px"}}/>
                                      <span dangerouslySetInnerHTML={{__html: reward}}/>
                                    </div>
                                ))}
                            </div>
                            
                            {/* divider */}
                            <Divider style={{marginTop: 5, marginBottom: 15}}/>
                            
                            {/* notes */}
                            <div className="card-dtl-r">
                                <div className="cr-title">
                                    Notes from luci
                                </div>
                                {(moreDetailNotes || []).map((note, index) => (
                                    <div className="card-dtl-item" key={index.toString()}>
                                        <img className={`w-20`} src={getNotesFromLuci(note, "src")} style={{marginTop: "-4px"}}/>
                                        <span>{getNotesFromLuci(note, "text")}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                
                <a
                    style={{color: "#333333", textDecoration: "none"}}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See Less Details
                    <img src={Arrow} className="arrow-tab-less ml-2"/>
                </a>
            </div>

        </div>
    );
}

export default SeeMoreDetails
